<template>
	<div class="assessBox">
		<div class="app-container">
			<div class="titlebox">
				<el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
				<div class="title">{{ promotion && promotion.name }}-{{ $route.query.name }}</div>
			</div>
			<div class="picbox">
				<div>
					<el-avatar :size="100" :src="promotion && promotion.avatarUrl">
						<img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" />
					</el-avatar>
				</div>
				<div>
					<h2>{{ promotion && promotion.name }}</h2>
					<p>{{ promotion && promotion.jobName }}&nbsp;&nbsp;{{ promotion && promotion.orgName }}</p>
					<p>
						<span>手机号码：{{ promotion && promotion.phoneNumber }}</span>
						<span>入职时间：{{ promotion && promotion.entryTime }}</span>
					</p>
				</div>
			</div>
			<div class="viewbox">
				<div>
					<!--<div style="">
            <div class="title-icon">{{panelTitle}}</div>
          </div>!-->
					<div style="width: 100%" class="promotionInfo">
						<div>
							<div>
								<label class="left_label"><div style="color: #333; font-size: 16px; width: 100%">调动前</div></label>
								<label class="right_label">
									<div></div>
								</label>
							</div>
							<div>
								<label class="left_label"><div style="color: #333; font-size: 16px; width: 100%">调动后</div></label>
								<label class="right_label">
									<div></div>
								</label>
							</div>
						</div>
						<div>
							<div>
								<label class="left_label">职族</label>
								<label class="right_label">
									<div class="inputDiv">{{ promotion && promotion.oldFamilyName }}</div>
								</label>
							</div>
							<div>
								<label class="left_label">职族</label>
								<label class="right_label">
									<div class="inputDiv">{{ promotion && promotion.newFamilyName }}</div>
								</label>
							</div>
						</div>
						<div>
							<div>
								<label class="left_label">职类</label>
								<label class="right_label">
									<div class="inputDiv">{{ promotion && promotion.oldClassName }}</div>
								</label>
							</div>
							<div>
								<label class="left_label">职类</label>
								<label class="right_label">
									<div class="inputDiv">{{ promotion && promotion.newClassName }}</div>
								</label>
							</div>
						</div>
						<div>
							<div>
								<label class="left_label">职类子类</label>
								<label class="right_label">
									<div class="inputDiv">{{ promotion && promotion.oldSubClassName }}</div>
								</label>
							</div>
							<div>
								<label class="left_label">职类子类</label>
								<label class="right_label">
									<div class="inputDiv">{{ promotion && promotion.newSubClassName }}</div>
								</label>
							</div>
						</div>
						<div>
							<div>
								<label class="left_label">职位</label>
								<label class="right_label">
									<div class="inputDiv">{{ promotion && promotion.oldOfficeName }}</div>
								</label>
							</div>
							<div>
								<label class="left_label">职位</label>
								<label class="right_label">
									<div class="inputDiv">{{ promotion && promotion.newOfficeName }}</div>
								</label>
							</div>
						</div>

						<div>
							<div>
								<label class="left_label">岗位</label>
								<label class="right_label">
									<div class="inputDiv">{{ promotion && promotion.oldJobName }}</div>
								</label>
							</div>
							<div>
								<label class="left_label">岗位</label>
								<label class="right_label">
									<div class="inputDiv">{{ promotion && promotion.newJobName }}</div>
								</label>
							</div>
						</div>
						<div>
							<div>
								<label class="left_label">职级</label>
								<label class="right_label">
									<div class="inputDiv">{{ promotion && promotion.oldLevel }}</div>
								</label>
							</div>
							<div>
								<label class="left_label">职级</label>
								<label class="right_label">
									<div class="inputDiv">{{ promotion && promotion.newLevel }}</div>
								</label>
							</div>
						</div>
						<div>
							<div>
								<label class="left_label">职级代码</label>
								<label class="right_label">
									<div class="inputDiv">{{ promotion && promotion.oldOfficeCode }}</div>
								</label>
							</div>
							<div>
								<label class="left_label">职级代码</label>
								<label class="right_label">
									<div class="inputDiv">{{ promotion && promotion.newOfficeCode }}</div>
								</label>
							</div>
						</div>
						<div v-if="$route.query.name == '晋升详情'">
							<div>
								<label class="left_label">职等</label>
								<label class="right_label">
									<div class="inputDiv">{{ promotion && promotion.oldJobEtc }}</div>
								</label>
							</div>
							<div>
								<label class="left_label">职等</label>
								<label class="right_label">
									<div class="inputDiv">{{ promotion && promotion.newJobEtc }}</div>
								</label>
							</div>
						</div>
						<div v-if="$route.query.name == '调岗详情'">
							<div>
								<label class="left_label">组织</label>
								<label class="right_label">
									<div class="inputDiv">{{ promotion && promotion.oldOrgName }}</div>
								</label>
							</div>
							<div>
								<label class="left_label">组织</label>
								<label class="right_label">
									<div class="inputDiv">{{ promotion && promotion.newOrgName }}</div>
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import * as api from '@/api/workbench';
export default {
	components: {},
	computed: {
		panelTitle() {
			if (this.$route.query.name === '晋升详情') {
				return '晋升录入';
			}
			return '调岗录入';
		},
		labelTitle() {
			if (this.$route.query.name === '晋升详情') {
				return '晋升为';
			}
			return '调岗为';
		}
	},
	data() {
		return {
			props: {
				disabled: 'disable'
			},
			promotion: {
				avatarUrl: '',
				entryTime: '',
				jobName: '',
				name: '',
				newClassName: '',
				newFamilyName: '',
				newJobEtc: '',
				newJobName: '',
				newLevel: '',
				newOfficeCode: '',
				newOfficeName: '',
				newOrgName: '',
				newSubClassName: '',
				officeName: '',
				oldClassName: '',
				oldFamilyName: '',
				oldJobEtc: '',
				oldJobName: '',
				oldLevel: '',
				oldOfficeCode: '',
				oldOfficeName: '',
				oldOrgName: '',
				oldSubClassName: ''
			},
			params: {
				id: ''
			},
			staffId: '',
			type: ''
		};
	},

	created() {},
	watch: {},
	mounted() {
		this.$nextTick(function () {
			this.params.id = this.$route.query.id;
			this.queryPromotionDetail();
		});
	},
	methods: {
		queryPromotionDetail() {
			api.queryPromotionDetail({
				request: {
					id: this.params.id
				}
			})
				.then((result) => {
					this.promotion = result;
				})
				.catch(() => {});
		}
	}
};
</script>
<style lang="scss" scoped>
.assessBox .app-container .viewbox .level_box_now {
	font-weight: bold;
}
.proSelect .el-input {
	width: 100%;
}
.inputDiv {
	width: 100%;
	height: 40px;
	line-height: 40px;
	font-weight: normal;
	padding: 0 15px;
	background-color: #fff;
	background-image: none;
	border-radius: 4px;
	border: 1px solid #dcdfe6;
	text-align: left;
	margin-bottom: 0 !important;
	vertical-align: middle;
}
// .inputDisable {
//   background: #f5f7fa;
//   color: #c0c4cc;
//   cursor: not-allowed;
// }
.picbox {
	padding: 0px 0 0 100px;

	background: #fff;

	margin: 0 20px;

	display: flex;

	align-items: center;

	margin-bottom: 20px;

	height: 140px;

	:first-child {
		margin-right: 10px;
	}

	h2 {
		margin: 10px 0 0 0;
	}

	p {
		margin: 15px 0px;

		color: #999999;
	}
}

.assessBox {
	.app-container {
		color: #484848;
		padding: 0;
		background-color: #f6f7fb;
		.titlebox {
			text-align: center;
			line-height: 17px;
			background: #fff;
			font: bold 18px/25px 'Microsoft YaHei';
			padding: 14px 40px;
			> .el-button {
				float: left;
				color: #484848;
				transform: translateY(-8px);
				font-size: 16px;
			}
			text-align: center;
			line-height: 17px;
			font: bold 18px/25px 'Microsoft YaHei';
			margin: 20px;
		}
		.viewbox {
			margin: 0 20px;
			background: #ffffff;
			min-height: 300px;
			padding: 50px;
			.promotionInfo {
				text-align: center;
				font-size: 14px;
				div {
					margin-bottom: 10px;
					div {
						width: calc(100% / 2);
						display: inline-block;
					}
				}
				.left_label {
					margin-right: 30px;
					color: #999999;
					text-align: right;
					width: 70px;
					display: inline-block;
					font-weight: normal;
				}
				.right_label {
					width: 70%;
				}
			}
		}

		.viewFoot {
			text-align: center;
			.el-button {
				margin: 15px;
				padding: 10px 45px;
			}
		}
	}
}
.title-icon {
	font-size: 18px;
	&:before {
		display: inline-block;
		width: 8px;
		height: 20px;
		background: #498df0;
		content: ' ';
		border-radius: 5px;
		vertical-align: top;
		margin-right: 5px;
	}
}
</style>
<style lang="scss">
.promotion {
	.el-input {
		width: 100% !important;
	}
}
.jobSelect .el-input--suffix {
	width: 100% !important;
}
</style>
